import { EntityService 		} from 'src/app/demo/service/entity.service';
import { Component, 
         OnInit,
         Input, 
         Output, 
         EventEmitter     	} from '@angular/core';
import { CommonsService 	} from 'src/app/demo/service/commons.service';

@Component({
  selector		: 'app-tracking',
  templateUrl	: './tracking.component.html',
  styleUrls		: ['./tracking.component.css']
})
export class TrackingComponent implements OnInit {

	@Input("info")		info	:any;
	@Output()			emitter			= new EventEmitter<any>();	
		
	pageInfo  			: any 	= {
		tabs	: {
			items	: [ 
				{ name: 'hotels', label: '_ACCOMMODATIONS', icon: 'home'	}
			], 
			cols		: 2,
			selected	: 'hotels'
		}
	};
	draggedItem			: any 	= {};	
	
	constructor(
		private commons			: CommonsService,
		private entityService	: EntityService
	){ this.init(); }

	init()				{
		this.pageInfo.results		= {};
		this.pageInfo.today 		= new Date();
		this.pageInfo.entities		= { 
			pickups : [],
			hotels	: []
		};
		this.pageInfo.search	= ""		
	}
	
	loadEntities()		{ 
		this.load("types"); 
		this.load("pickups"); 
	}

	async ngOnInit()	{ 
		this.pageInfo.map		= { zoom: 10, lat: 39.639938, lng: 2.894679 };		
		this.pageInfo.pickups	= this.pageInfo.pickups || [];
		await this.loadEntities();
	}

	setTabItem($event)	{	this.pageInfo.tabs.selected = $event.item.id;	}
    
	onUpload(event) 	{	for(let file of event.files) { this.pageInfo.uploadedFiles.push(file); }
					        this.commons.generateToast('File Uploaded','','info');
						}
	
	getMarkers($type)	{
		switch($type){
			case "hotels"	:
			case "pickups"			: 	return this.pageInfo.entities.pickups.filter(item=>item.selected);
			case "selected"			:	return this.pageInfo.pickups;
		}
	}
	
	getLocationParsed($item){	return parseFloat($item["latitude"]).toFixed(4) + " , " + parseFloat($item["longitude"]).toFixed(4);	}
	
	action($type,$item?,$extra?){
		switch($type){
			case "view_pickup"		:	this.emitter.emit({ action: 'view_pickup', item: $item 	});	break;
			case "togglePickup"		: 	let isPickupSelected 			= this.pageInfo.pickups.some(item=>item.name==$item.name);
										this.pageInfo.entities.pickups.forEach(item=>{ item.selected = false; });
										$item.selected 					= !isPickupSelected;
										this.pageInfo.pickups			= isPickupSelected?[]:[$item];
										break;
			case "toggleItemField"	:	switch($extra){
											case "type":	$item.editing 	= $item.editing==true?false:true; break;
										}
										break;
			case "setItemField"		:	switch($extra){
											case "type":	$item.type 		= $item.optionSelected;
															$item.editing	= false;
															break;
										}
										break;
			case "setMarker"		: 	this.pageInfo.pickups.forEach(item=>{
											item.latitude	= $item.coords.lat,
											item.longitude	= $item.coords.lng
										});
										break;
		}
	}

	async savePickups()				{
		let records = this.pageInfo.entities["pickups"].filter(item=>item.dirty);
		if(records.length==0){ 
			this.commons.generateToast("_NOTICE", "_NO_CHANGES","info");
			return false; 
		}

		await this.entityService.postJSON(
			this.entityService.getUrl('pickup_persist'), { records: records }
		).then(response=>{
			this.pageInfo.spinner = false;
			if(response["success"]!=true){
				this.commons.generateToast("_ERROR", "_ERROR_SAVING","error");
				console.log("RESPONSE",response);
				return false;
			}
			this.commons.generateToast("_SUCCESS", "_SUCCESS_SAVING","success");
			this.load("pickups");		
			return true;
		}).catch(response=>{
			this.pageInfo.spinner = false;
			this.commons.generateToast("_ERROR", "_ERROR_SAVING","error");
			console.log("RESPONSE",response);				
		});
	}

	getFilteredEntity($type){
		switch($type){
			case "pickupTree"	: 	
				return this.pageInfo.entities["pickups"].filter(item=>{
					return (undefined==this.pageInfo.search || this.pageInfo.search=="")
								?true
								:item.name.toLowerCase().includes(this.pageInfo.search.toLowerCase());
				});
		}
	}

	search(item,$event) {
		if(undefined==item.entityList){ return false; }
		this.pageInfo.results[item.field] =  this.pageInfo.entities[item.entityList].filter(item => item.name.includes($event.query)).map(item=>item.name);
	}

	async load($entity){
		this.pageInfo.spinner 			= true;	
		switch($entity){
			case 'pickups' 	:	this.loadPickups(); break;					
			case 'types'	: 	this.loadTypes();	break;
		}
		this.pageInfo.spinner 			= false;	
	}

	async loadPickups(){
		const $entity 	= "pickups";
		let response 	= await Promise.resolve(this.entityService.getRequest("pickups"));
		
		this.pageInfo.entities[$entity]	= response["data"].map(item=>{
			item["dirty"]			= false;					 
			item["name_orig"]		= item["name"];
			item["type_orig"]		= item["type"];
			item["latitude_orig"]	= item["latitude"];
			item["longitude_orig"]	= item["longitude"];						
			return item;
		});

		this.pageInfo.entities["areas"]	= this.pageInfo.entities[$entity].reduce((a,item)=>a.some(aItem=>item.area==aItem.name)?a:[...a,{ name: item.area, selected: true }],[]);
		this.action('toggleViewAll');
	}

	async loadTypes(){
		const $entity = "types";
		let response  = await Promise.resolve(this.entityService.getRequest("pickup_types"));
		this.pageInfo.entities[$entity]	= response["data"].map(item=>{
			item["label"] 			= this.commons.getTranslate(item["value"]);
			return item;
		});
		this.pageInfo.entities[$entity+"_original"] = this.pageInfo.entities[$entity];
		
		this.pageInfo[$entity]			= {
			field		: "type",
			entityList	: "types"
		};
	}
}