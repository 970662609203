import { CommonsService 			} from './../commons.service';
import { HttpClient               	} from '@angular/common/http';
import { Injectable               	} from '@angular/core';
import { AngularFirestore,
         AngularFirestoreModule   	} from '@angular/fire/firestore';
import { AngularFireAuth          	} from '@angular/fire/auth';
import { AngularFireStorage 		} from '@angular/fire/storage';
import { first, take, map, mergeMap	} from 'rxjs/operators';
import { Observable, forkJoin 		} from 'rxjs';
import { combineLatest 				} from 'rxjs';
import { data 						} from '../../view/mapconfig/mapcheckdistances/data/info';

@Injectable()
export class FirebaseService {

	info			: any 	= {};
	callbacks		: any 	= {};
	subscriptors	: any 	= { bookings: {} };
	dmcRef			: any;
	dmcDestRef		: any;
	bookings		: any[]	= [];
	transfers		: any[]	= [];
	flights			: any[]	= [];
	subscriptions	: any[] = [];
		
  	constructor(  
		private afs     	: AngularFirestore,
		private afAuth  	: AngularFireAuth,
		private commons 	: CommonsService,
		public 	afStorage	: AngularFireStorage
	){}

	public init(info)								{	
		console.group("[FirebaseProvider] Init");
		console.log("Info",info);
		
		this.setController("globalFlight",	()=>{});
		this.setController("booking",		()=>{});
		this.setController("transfer",		()=>{});
		this.setController("routes", 		()=>{});
		this.setController("demoBooking",	()=>{});
	}
	

	doAction($info){
		switch($info.type){
			case "open"		: 
				break;

			case "close"	: 
				break;

			case "action"	: 
				switch($info.action){
					case "execute"	: 	
						let values 		= $info.values;
						let jsonValues 	= {};
						let validated 	= true;

						["path","operation","values"].forEach(field=>{
							validated = validated && values[field]!=undefined && values[field]!="";
						})

						if(!validated){
							this.commons.generateToast("_ERROR","_INVALID_FORM_VALUES","error");
							return false;
						}

						try {
							jsonValues = JSON.parse(values["values"]);
						} catch(e){
							this.commons.generateToast("_ERROR","_JSON_NOT_VALID", "error");
							return false;
						}

						jsonValues["timestamp"] = new Date();

						try {
							switch(values["operation"]){
								case "_FIREBASE_OPERATION_ADD"		: 	
									this.addDoc(values["path"],jsonValues);
									break;

								case "_FIREBASE_OPERATION_SET"		: 	
									jsonValues["id"] = values["id"];
									this.setDoc(values["path"],jsonValues);
									break;

								case "_FIREBASE_OPERATION_UPDATE"	: 	
									jsonValues["id"] = values["id"];
									this.updateDoc(values["path"],jsonValues);
									break;

								case "_FIREBASE_OPERATION_DELETE"	:	
									this.deleteDoc(jsonValues);
									break;
							}
							this.commons.generateToast("_INFO","_FIREBASE_OPERATION_SUCCEED", "info");

						} catch(e){
							this.commons.generateToast("_FIREBASE_OPERATION_FAILED",e.message, "error");
							return false;
						}
						break;

					case "cancel"	:
						break;
				}
		}
	}

	public setController(type,callback)				{ 	this.callbacks[type] = callback; 	}
	
	public getController(type)						{	return this.callbacks[type];		}
	
	async addDoc($path, $info)						{	let doc = await Promise.resolve(this.afs.collection($path).add($info));																
														await Promise.resolve(doc.update({ id: doc.id }));	
														return doc;									
													}

	async updateDoc($path, $info)					{
		let response = { success: true };
		try {
			await this.afs.doc($path).update($info);			
		} catch(e){
			console.log("[firebase::updateDoc] Error",e);
			response["success"] = false;
		}
		return response;
	}

	/**
	 * create new doc with id as $info["id"]
	 */
	async setDoc($path, $info){	
		return await this.afs.collection($path).doc($info["id"]||$info["uid"]).set($info);	
	}

	async deleteDoc($path)							{	let response = await Promise.resolve(this.afs.doc($path).delete());	
														return response;
													}

	public async getDmcs()							{	
		let dmcs = await this.afs.collection('dmcs').ref.get();
		return !dmcs?[]:dmcs.docs.map(doc=>({ ref: doc.ref, ...doc.data() }));
	}	

	setGeneralRef(idDmc, destinationId)				{	
		this.dmcRef		= 	this.afs.collection('dmcs').doc(isNaN(idDmc)?idDmc:idDmc.toString())
		this.dmcDestRef	= 	this.dmcRef.collection('destinations').doc(destinationId);
	}
	
	subscribeEntity($entityRef,$queryFn?)			{	
		return this.afs.collection($entityRef,$queryFn).valueChanges(); 	
	}
	
	subscribeEntityDoc($entityRef)					{
		if(!this.subscriptions.some(item=>item==$entityRef)){
			this.subscriptions.push($entityRef);
		}
		return this.afs.doc($entityRef).valueChanges(); 					
	}													
	
	// public async createDestination($info)					{
	// 	let docInfo 	= { name: $info.name };
	// 	let docPath		= "/countries/spain/destinations";
	// 	$info.id		= "123";
	// 	// await this.addDoc(docPath, docInfo);
	// 	let response 	= await this.setDoc(docPath, docInfo);
	// 	console.log("CreateDestination",response);
	// }

	public subscribeDMC	(info)						{		
		this.setGeneralRef(info.id, info.destinationId);
		this.subscriptors.globalFlights = 	this.afs.collection('flights').valueChanges().subscribe( data => this.callbacks.globalFlight(data) );		
		this.subscriptors.dmc 			=	this.dmcDestRef.valueChanges().subscribe(data => this.callbacks.dmc(data));

		// REMOVE MOCKED BOOKINGS
		this.subscriptors.demoBooking	=	this.dmcDestRef.collection('bookings'							).doc('K1611267').valueChanges().subscribe(data=>{ this.callbacks.demoBooking(data) });		
		this.subscriptors.bookings 		= 	this.dmcDestRef.collection('bookings', 	ref => ref.limit(25)	).valueChanges().subscribe( data => { this.callbacks.booking(data) 	});
		this.subscriptors.transfers		= 	this.dmcDestRef.collection('transfers',	ref => ref.limit(25)	).valueChanges().subscribe( data => { this.callbacks.transfer(data) });
		this.subscriptors.routes		=	this.dmcDestRef.collection('routes'								).valueChanges().subscribe( data => { this.callbacks.routes(data)	});		
	}

	public unsubscribeDMC ()						{	Object.keys(this.subscriptors).forEach(subscriptionType =>{ this.subscriptors[subscriptionType] = {} });	}
	public unsubscribeAll()							{	
		this.unsubscribe('all_bookings'); 
		this.unsubscribe('dmc');	
	}

	public unsubscribe(type,key?)					{	
		switch(type){	
			case 'bookings'		:	this.subscriptors.bookings[key](); break;
			case 'all_bookings'	:
				if(undefined!==this.subscriptors.bookings){
					console.log("Unsubscribing bookings");
					let userKeys = Object(this.subscriptors.bookings);
					if(undefined!==userKeys.getKeys){
						userKeys.getKeys().forEach(key => {
							console.log("User",key);
							this.subscriptors.bookings[key]();	// Empty call to unsubscribe onSnapshot		
						});
					}
				}
				break;

			case 'transfers'	:	this.subscriptors.transfers[key](); break;

			case 'all_transfers':
				if(undefined!==this.subscriptors.transfers){
					console.log("Unsubscribing transfers");
					let userKeys = Object(this.subscriptors.transfers);
					if(undefined!==userKeys.getKeys){
						userKeys.getKeys().forEach(key => {
							console.log("User",key);
							this.subscriptors.transfers[key]();	// Empty call to unsubscribe onSnapshot		
						});
					}
				}
				break;

			case 'flights'			:	this.subscriptors.flights[key](); break;

			case 'all_flights'		:
				if(undefined!==this.subscriptors.flights){
					console.log("Unsubscribing flights");
					let userKeys = Object(this.subscriptors.flights);
					if(undefined!==userKeys.getKeys){
						userKeys.getKeys().forEach(key => {
							console.log("User",key);
							this.subscriptors.flights[key]();	// Empty call to unsubscribe onSnapshot		
						});
					}
				}
				break;

			case 'dmc'			:				
				if(undefined!==this.subscriptors.dmc){
					console.log("Unsubscribing dmc");
					this.subscriptors.dmc.unsubscribe();
				}
				break;

			default				:	console.log("Unknown type of subscription <"+type+">");
		}
	}

	async getDataByRef(reference)					{	
		let result =  await (this.afs.doc(reference).ref.get()); 
		return {...result.data(),ref : result.ref}	
	}
	
	async updateItemByRef(ref,data)					{	
		try			{	
			await this.afs.doc(ref).update(data); 
			return { success : true };	
		} catch(e)	{ 	
			return { success : false, message: e };											
		}											
	}
	
	async updateCustomerBooking(reference,values)	{	return Promise.resolve(this.dmcDestRef.collection('bookings').doc(reference).update(values));				}
	
	async updateTransfer(reference,values)			{	return Promise.resolve( this.dmcDestRef.collection('transfers').doc(reference.toString()).update(values));	}

	async sendNotification(type,reference,messages)	{	switch(type){ 
															case "issue":	return Promise.resolve( this.dmcDestRef.collection('issues').doc(reference).update({messages:messages})); 
														}
													}
	
	async displayServiceOnTablet()					{	return Promise.resolve( this.afs.collection('servicesInfo').doc('tablet').collection('1').doc('display').update({id:"1",type:"tours"}));	}

	async getCurrentTripUser(uid, currentTrip)		{	if(!uid || !currentTrip) { this.commons.generateToast('_ERROR','_MISSING_INFO', 'error'); return;};
														let user	=	 await Promise.resolve(this.afs.collection('users').doc(uid).collection('trips').doc(currentTrip).ref.get());
														return user.exists ? user.data() : null;
													}
	subscribeAssistantChats(dmcId, destinationId)	{	this.setGeneralRef(dmcId,destinationId);
														return this.dmcDestRef.collection('assistantChats').valueChanges();
													}

	subscribeRoom(refRoom)							{	return this.afs.doc(refRoom).collection('messages').valueChanges(); }
	
	async subscribeRooms(refRooms)					{	return combineLatest(refRooms);	}
	
	async getPromisedMessages(idRoom)				{	return await Promise.resolve(this.afs.firestore.doc('rooms/'+idRoom).collection('messages').get()); }
	
	async updateAssistantChat(idChat, updateInfo)	{	return await Promise.resolve(this.dmcDestRef.collection('assistantChats').doc(idChat).update(updateInfo))	}

	async addMessageToRoom(refRoom, body)			{	return await Promise.resolve(this.afs.doc(refRoom).collection('messages').add(body));	}
									
	async getuserInfo(uid)							{	
		if(!uid) return;
		let userInfoRef, userInfo;
		
		// 1. Check if user is in USERS collection
		// 2. Check if user is in STAFFUSERS collection														
		
		try {
			userInfoRef 	= await Promise.resolve(this.afs.doc("users/"+uid).ref.get());
			userInfoRef 	= userInfoRef.exists?userInfoRef:await Promise.resolve(this.afs.collection('staffUsers').doc(uid).ref.get());														
		
			if(!userInfoRef.exists)	{	throw new Error("_USER_NOT_FOUND");		} 
			
			userInfo		=	userInfoRef.data();
			let user, payload;
			
			switch(userInfo.profile){
				default				:	
					payload 			= {	success	: true			};
					break;

				case 'admin'		:
					payload 			= { ...userInfo, success: true	};
					break;
				
				case 'dmc'		:	
					const dmcRef 		=	await Promise.resolve(this.afs.collection('dmcs').doc(userInfo.dmc).ref.get());
					if(!dmcRef.exists){ return {}; }
					const dmcInfo		=	dmcRef.data();
					user				=	await Promise.resolve(this.afs.collection('dmcs').doc(userInfo.dmc).collection('staff').doc(uid).ref.get());
					if(!user.exists	){ return {};	}
					payload				= 	{ 	
						...userInfo, 
						idDmc 		: userInfo.dmc, 
						nameDmc 	: dmcInfo.company_name,
						success		: true
					};					
					break;

				case 'reseller'	:	
					const mainReseller	=	await Promise.resolve(this.afs.collection('resellers').doc(userInfo.reseller).ref.get())
					user				=	await Promise.resolve(this.afs.doc(userInfo.ref).ref.get());
					payload 			=  	{ 	
						...userInfo, 
						resellerId	: mainReseller.data().id, 
						resellerName: mainReseller.data().name,
						success		: true																							
					};
					 break;
			}

			console.log("PAYLOAD",payload);
			return payload;
		} catch(e){
			this.commons.generateToast("_ERROR",e,"error");
			return { success: false, error: e };
		}
	}

	async getInfoUser(uid)							{	
		if(!uid) return;
		return Promise.resolve(this.afs.collection('users').doc(uid).ref.get());
	}

	async getPromiseAssistantChats()				{
		let chats	=	await Promise.resolve(
									this.afs.firestore.collection('dmcs')
									.doc(this.commons.userInfo.dmc.id)
									.collection('destinations')
									.doc(this.commons.userInfo.destination.id)
									.collection('assistantChats')
									.get()
						);
		console.log('chats', chats);
		let result	=	[];
		chats.forEach(data =>{
			console.log('DATA', data.data());
			result.push(data.data());
		});
		console.log('returning', result);
		return result;
	}

	async subscribeRoomsAssistantChats()			{
		let refChats	=	await this.getPromiseAssistantChats();
		const obs		=	refChats.map(el => this.afs.doc(el.ref).collection('messages').valueChanges());

		console.log(refChats);
		console.log(obs);
		
		return combineLatest(obs);
	}

	getRef(ref)										{	return this.afs.doc(ref);															}

	// COMBINELATEST
	subscribeMultiObservables(items)				{	const observables = items.map(item => this.getRef(item).valueChanges());
												    	return combineLatest(observables)
													}

	// INIT METHODS ROLES AND PROFILES TO GUARD
	async getProfileInfo(profileRef)				{	return await Promise.resolve(this.afs.doc(profileRef).ref.get());	}
	
	async getRoleInfo(rolRef)						{	return await Promise.resolve(this.afs.doc(rolRef	).ref.get());	}
	
	async getInfoFromRef(ref)						{	return await Promise.resolve(this.afs.doc(ref		).ref.get()); 	}
	
	/**
	 * Get the profiles stored in firebase from user. Need to change to backend
	 * @param uid id from user
	 */
	async getProfilesUser(uid)						{	
		if(!uid) return;
		
		//get the profile from the user
		let user		 	= await this.getInfoUser(uid);
		const userProfile	= user.data().profile;
		if(!userProfile) return [];
		const profileInfo	= await this.getProfileInfo(userProfile);

		//get the rols and return them
		if( !profileInfo || !profileInfo.data() || !profileInfo.data().roles) return [];
		return profileInfo.data().roles;
	}

	// END METHODS ROLES AND PROFILES
	/**
	 * Fast way to add a set of areas given a destination. A must change is add the destination by param 
	 * @param area 
	 */
	async addAreas(area)								{	
		await Promise.resolve(this.afs.collection('destinations').doc(this.commons.userInfo.destination.id).collection('areas').doc(area.id.toString()).set(area));
	}

	async getAreasFromDestination(destinationId:string)	{	
		return await Promise.resolve(this.afs.firestore.collection('destinations').doc(destinationId).collection('areas').get());	
	}

	/**
	 * Method to determine the differents zones for TRANSFERS
	 * @param dmcId 
	 * @param destinationId 
	 * @param data array of zones which their areas
	 */
	async updateZoneDestination(dmcId:string,destinationId:string,data){
		console.log('going to update', data);
		return await Promise.resolve(this.afs.collection('dmcs').doc(dmcId).collection('destinations').doc(destinationId).update({ zones : data }))
	}

	/**
	 * Method to determine the differents zones for PICKUPS
	 * @param dmcId 
	 * @param destinationId 
	 * @param data array of zones which their areas
	 */
	async updatePickupsZonesDestination(dmcId : string, destinationId: string, data){
		let cleanData 		= data.map(zone => {return {areas : zone.areas.map(el => el['ref'] ? el['ref'] : el), id : zone.id, name : zone.name}});
		let destinationDoc 	= this.afs.collection('dmcs').doc(dmcId).collection('destinations').doc(destinationId);
		return await Promise.resolve(destinationDoc.update({ pickupZones : cleanData }));
	}

	subscribeMainInfoDestinationByDmc(dmcId:string,destinationId:string){
		return this.afs.collection('dmcs').doc(dmcId).collection('destinations').doc(destinationId);
	}

	async getPromisedZonesByDestination(dmcId : string, destinationId : string){
		try{
			let infoDestination	=	await this.afs.collection('dmcs').doc(dmcId).collection('destinations').doc(destinationId).ref.get();
			if(!infoDestination){ return null }
			let infoZones		= 	await Promise.all(infoDestination.data().zones.map(async zone => {
				return {
					id		:	zone.id,
					name	:	zone.name,
					refAreas:   zone.refAreas,
					areas	:	await this.getInfoAreas(zone.refAreas)
				}
			}));
			return infoZones || null;	
		}catch(e){
			return null;
		}
		
	}

	/**
	 * Return an array of zones which their areas defined for the pickups
	 * @param dmcId 
	 * @param destinationId 
	 */
	async getPickupsZones(dmcId : string, destinationId	:	string){
		let infoDmcDestination	=	await Promise.resolve(this.afs.collection('dmcs').doc(dmcId).collection('destinations').doc(destinationId).ref.get());
		if(!infoDmcDestination.data()) 				{ return this.commons.generateToast('_ERROR','_NO_DATA_FOUND', 			'error');	}
		if(!infoDmcDestination.data().pickupZones)	{ return this.commons.generateToast('_ERROR','_NO_PICKUP_ZONES_FOUND',	'error');	}
		let pickUpZones = await Promise.all(infoDmcDestination.data().pickupZones.map(async zone => {
			return {
				id		:	zone.id,
				name	:	zone.name,
				areas	:	await this.getInfoAreas(zone.areas)
			}
		}));
		return pickUpZones;
	}
	
	async getInfoAreas(areas){
		if(undefined==areas){ return []; }
		const infoAreas	=	await Promise.all(areas.map(refArea => this.getInfoFromRef(refArea)));
		let resultAreas =	infoAreas.map((element : any) => { return { ref : element.ref,...element.data()}});
		return resultAreas;		
	}

	async getDestinationsFromDmc(dmcId:string){
		let destinationsCol	=	await Promise.resolve(this.afs.firestore.collection('dmcs').doc(dmcId).collection('destinations').get());
		let destinations	=	[];
		destinationsCol.forEach(destination => {
			destinations.push(destination.data());
		});
		return destinations;
	}

	async getAvailablesProfiles(category){
		let profilesDoc	=	await Promise.resolve(this.afs.firestore.collection('profiles').get());
		let profiles	=	profilesDoc.docs.map(profile => profile.data());
		return profiles.filter(profile => profile.category == category);
	}
	async getProfiles(data){

		let profilesDoc	=	await Promise.resolve(this.afs.firestore.collection('profiles').get());
		let profiles	=	[];
		profilesDoc.forEach(profile =>{
			if(data.types.some(type => type == profile.data().category)){
				profiles.push(profile.data());
			}
		});
		return profiles;
	}

	async getRolesFromProfile(profile)	{	
		if(!profile) return [];
		const infoProfile 	= 	await Promise.resolve(this.afs.firestore.collection('profiles').where('type','==',profile).get())
		let roleProfiles	=	[];
		infoProfile.forEach(role =>{	roleProfiles = [].concat.apply([],role.data().roles);	})
		console.log(roleProfiles);
		return roleProfiles;
	}

/*------------------------------------------------------------------------------------------------------
	USER MANAGEMENT
--------------------------------------------------------------------------------------------------------*/
	async updateUser(userInfo){

		if(!userInfo["id"]){ return false; }
		
		let refInfo;
		let type = this.commons.getEntityProfile(userInfo["profile"]);
		switch(type.profile){
			case "reseller"	:	refInfo = await Promise.resolve(this.afs.collection('resellers').doc(userInfo["resellerId"]).collection('staff').doc((userInfo['id']).toString()).ref.get());
			break;
			case "dmc"		:	refInfo = await Promise.resolve(this.afs.collection('dmcs').doc(userInfo["dmcId"]).collection('staff').doc((userInfo["id"]).toString()).ref.get());
								break;
			default			:	return false;
		}

		let confirmExistenceUser = await Promise.resolve(refInfo);
		console.log(confirmExistenceUser);
		if(confirmExistenceUser.exists){
			await Promise.resolve(this.afs.doc(refInfo.ref).update(userInfo));
			return true;
		}else{
			return false;
		}
	}

	/**CRUD users staff from any dmc */
	async addUserToStaff(data){
		let userInfo	= data["userInfo"];
		let uid			= data["uid"];
		let entityId	= data["entityId"];

		let message:any = { success: true };
		let ref;
		userInfo["id"]	= uid;
		let userDoc;
		try {
			let type = this.commons.getEntityProfile(userInfo["profile"] || 'reseller_operator');
			switch(type.profile){
				case "reseller"	:	let resellerDoc:any = entityId==null
															?await Promise.resolve(this.afs.collection('resellers').add({name: "Tourinia Reseller"}))
															:await Promise.resolve(this.afs.collection('resellers').doc(entityId).ref);
														
									await Promise.resolve(resellerDoc.update({id:resellerDoc.id}));

									// Create Reseller user
									userDoc = resellerDoc.collection("staff").doc(uid);
									await Promise.resolve(userDoc.set({ 
																			...userInfo, 
																			profile		: entityId==null?"reseller_admin":"reseller_operator", 
																			resellerId	: resellerDoc.id 
																		}));

									// Create User dictionary reference
									await Promise.resolve(this.afs.collection("staffUsers").doc(uid).set({ref:userDoc, type : 'reseller', reseller : resellerDoc.id}));									
									break;

				case "dmc"		:	let dmcDoc	:	any =	await Promise.resolve(this.afs.collection('dmcs').doc(entityId).ref);
									userDoc				=	dmcDoc.collection('staff').doc(uid);
									console.log('have ref doc',dmcDoc)
									await Promise.resolve(userDoc.set({
										...userInfo,
										profile	: userInfo['isAdmin'] ? 'dmc_admin' : 'dmc_operator',
										dmcId	:	dmcDoc.id
									}));
									console.log('going to save on staffusers',uid,userDoc,dmcDoc.id);
									// Create User dictionary reference
									await Promise.resolve(this.afs.collection("staffUsers").doc(uid).set({ref:userDoc, type : 'dmc', dmc : dmcDoc.id}));									
									message.userId	=	uid;
									break;
				default			:	return false;
			}

			// await Promise.resolve(this.afs.collection('staffUsers').doc(userId).set({dmc : dmcId}));
			// await Promise.resolve(this.afs.collection('dmcs').doc(dmcId).collection('staff').doc(userId).set(rowData));			
		} catch(e){
			console.log(e)
			message.success = false
			message.error	= e;
		}
		return message;
	}

	/**
	 * add driver to company
	 * @param data 
	 * @returns 
	 */
	async addDriverToCompany(data){
		try{
			let userInfo	=	data.userInfo.info;
			if(data["uid"]){
				userInfo['id']	= data["uid"];
			}
			let path 		= await Promise.resolve(this.afs.collection('dmcs').doc(data.idDmc).collection('destinations').doc(data.idDestination));
			let driverDoc	= await Promise.resolve(path.collection('drivers').add(userInfo));
			
			driverDoc.update({ id : driverDoc.id, ref: driverDoc });

			if(data["uid"]){
				await Promise.resolve(this.afs.collection("staffUsers").doc(data["uid"]).set({
					ref			: driverDoc, 
					type 		: 'driver',
					dmc 		: data.idDmc, 
					destination : data.idDestination
					}));
			}
			return { success : true }
		}catch(e){
			console.log(e);
			return { success : false, error : e}
		}	
	}

	/**
	 * update driver info
	 * 
	 * @param data 
	 * @returns 
	 */
	async updateDriver(data){
		try{
			let userInfo	=	data.userInfo.info;
			this.afs.doc(userInfo.ref).update(userInfo);
			// let path = await  Promise.resolve(this.afs.collection('dmcs').doc(data.idDmc).collection('destinations').doc(data.idDestination))
			// path.collection('drivers').doc(data.userInfo.id).update(userInfo);
			return true;
		}catch(e){
			console.log(e);
			return false;
		}
	}

	getStaff(data){
		switch(data.type){
			case "reseller"	:	return this.afs.collection('resellers').doc(data.id).collection('staff').valueChanges();
			case "dmc"		:	return this.afs.collection('dmcs').doc(data.id).collection('staff').valueChanges();
		}
	}

/*------------------------------------------------------------------------------------------------------
 	DMCS 
 -------------------------------------------------------------------------------------------------------*/

	getStaffFromDmc(dmcId:string){
		return this.afs.collection('dmcs').doc(dmcId).collection('staff').valueChanges();
	}

	async checkExistUserDmc(user,dmcId:string){
		const userInfo	=	await Promise.resolve(this.afs.firestore.collection('dmcs').doc(dmcId).collection('staff').where('email','==',user.email).get());
		let userExists = [];
		userInfo.forEach(data =>{
			userExists.push(data);
		})
		return userExists.length == 0;
	}

	/**CRUD users staff from any dmc */
	async addUserToStaffDmc(rowData,userId,dmcId:string){
		let message:any = { success: true };
		rowData.id = userId;
		try {
			await Promise.resolve(this.afs.collection('staffUsers').doc(userId).set({dmc : dmcId}));
			await Promise.resolve(this.afs.collection('dmcs').doc(dmcId).collection('staff').doc(userId).set(rowData));			
		} catch(e){
			message.success = false
			message.error	= e;
		}
		return message;
	}

	async updateUserStaffDmc(rowData,dmcId:string){
		if(!rowData.id){ return false; }
		let confirmExistenceUser = await Promise.resolve(this.afs.collection('dmcs').doc(dmcId).collection('staff').doc((rowData.id).toString()).ref.get());
		if(confirmExistenceUser.exists){
			let updatedUser = await Promise.resolve(this.afs.collection('dmcs').doc(dmcId).collection('staff').doc(rowData.id).update(rowData));
			return true;
		}else{
			return false;
		}
	}

	async removeUserToStaffDmc(rowData,dmcId:string){
		await Promise.resolve(this.afs.collection('staffUsers').doc(rowData.id).delete());
		await Promise.resolve(this.afs.collection('dmcs').doc(dmcId).collection('staff').doc(rowData.id).delete());
	}

	async saveAvatarUserStaff(rowData, file, dmcId:string){
			const filePath 		= 'dmcs/' + dmcId.toString() +'/staff'
			const ref 			= this.afStorage.ref(filePath);
			const dataLoaded	= await ref.put(file);
			const urlImg		= await this.afStorage.ref(filePath).getDownloadURL().pipe(first()).toPromise();
			console.log('IMATGE LINK ÉS', urlImg);
			return urlImg;
	}

	

/*------------------------------------------------------------------------------------------------
	TOURS
--------------------------------------------------------------------------------------------------*/

	/** CRUD Tours and Variants from any dmc */
	getVariantInfo(dmcId:string, destinationId:string, tourId:string, variantId){
		console.log(dmcId, destinationId, tourId, variantId);
		return this.afs.collection('dmcs').doc(dmcId.toString())
					.collection('destinations'	).doc(destinationId.toString())
					.collection('tours'			).doc(tourId.toString())
					.collection('variants'		).doc(variantId.toString())
					.valueChanges();
	}

	async setVariantInfo(dmcId:string,destinationId:string,tourId:string,variantId:string,variantInfo:any){
		const variants		=   this.afs.collection('dmcs'			).doc(dmcId.toString())
										.collection('destinations'	).doc(destinationId.toString())
										.collection('tours'			).doc(tourId.toString())
										.collection('variants');

		const itemRef		= await Promise.resolve(variants.doc(variantId.toString()).ref.get());
		
		if(itemRef.exists)	{ return await Promise.resolve(variants.doc(variantId.toString()).update(variantInfo));	}
		else 				{ return await Promise.resolve(variants.doc(variantId.toString()).set(variantInfo));	}	
	}

	async setTourInfo(dmcId:string,destinationId:string,tourId:string, tourInfo:any){
		const tours			=   this.afs.collection('dmcs'			).doc(dmcId.toString())
										.collection('destinations'	).doc(destinationId.toString())
										.collection('tours'			);
	
		const itemRef		= await Promise.resolve(tours.doc(tourId.toString()).ref.get());

		if(itemRef.exists)	{ return await Promise.resolve(tours.doc(tourId.toString()).update(tourInfo));			}
		else				{ return await Promise.resolve(tours.doc(tourId.toString()).set(tourInfo));				}
	}

	/** Countries */
	async getInfoListCountries(listCountries){
		let countries	=	await Promise.all(listCountries.map(async country => await this.afs.collection('countries').doc(country).ref.get()));	
		return countries.map((el:any) => {return {...el.data()}});
	}

}
