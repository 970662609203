import { IntegrationFactoryService          } from './demo/service/integrations/factory.service';
import { AuthGuard                          } from './demo/service/auth/auth.guard';
import { NgModule							} from '@angular/core';
import { FormsModule						} from '@angular/forms';
import { BrowserModule						} from '@angular/platform-browser';
import { BrowserAnimationsModule			} from '@angular/platform-browser/animations';
import { LocationStrategy, 
		 HashLocationStrategy,				
         registerLocaleData					} from '@angular/common';
import { AppRoutes							} from './app.routes';
import { AccordionModule, 
		 ProgressSpinnerModule, 
		 SidebarModule,						
         ConfirmationService                } from 'primeng/primeng';
import { AutoCompleteModule					} from 'primeng/primeng';
import { BreadcrumbModule					} from 'primeng/primeng';
import { ButtonModule						} from 'primeng/primeng';
import { CalendarModule						} from 'primeng/primeng';
import { CarouselModule						} from 'primeng/primeng';
import { ChartModule						} from 'primeng/primeng';
import { CheckboxModule						} from 'primeng/primeng';
import { ChipsModule						} from 'primeng/primeng';
import { CodeHighlighterModule				} from 'primeng/primeng';
import { ConfirmDialogModule				} from 'primeng/primeng';
import { ColorPickerModule					} from 'primeng/primeng';
import { SharedModule						} from 'primeng/primeng';
import { ContextMenuModule					} from 'primeng/primeng';
import { DataGridModule						} from 'primeng/primeng';
import { DataListModule						} from 'primeng/primeng';
import { DataScrollerModule					} from 'primeng/primeng';
import { DataTableModule					} from 'primeng/primeng';
import { DataViewModule						} from 'primeng/dataview';
import { DialogModule						} from 'primeng/primeng';
import { DragDropModule						} from 'primeng/primeng';
import { DropdownModule						} from 'primeng/primeng';
import { EditorModule						} from 'primeng/primeng';
import { FieldsetModule						} from 'primeng/primeng';
import { FileUploadModule					} from 'primeng/primeng';
import { GalleriaModule						} from 'primeng/primeng';
import { GMapModule							} from 'primeng/primeng';
import { GrowlModule						} from 'primeng/primeng';
import { InputMaskModule					} from 'primeng/primeng';
import { InputSwitchModule					} from 'primeng/primeng';
import { InputTextModule					} from 'primeng/primeng';
import { InputTextareaModule				} from 'primeng/primeng';
import { LightboxModule						} from 'primeng/primeng';
import { ListboxModule						} from 'primeng/primeng';
import { MegaMenuModule						} from 'primeng/primeng';
import { MenuModule							} from 'primeng/primeng';
import { MenubarModule						} from 'primeng/primeng';
import { MessagesModule						} from 'primeng/primeng';
import { MessageModule                      } from 'primeng/primeng';
import { MultiSelectModule					} from 'primeng/primeng';
import { OrderListModule					} from 'primeng/primeng';
import { OrganizationChartModule			} from 'primeng/primeng';
import { OverlayPanelModule					} from 'primeng/primeng';
import { PaginatorModule					} from 'primeng/primeng';
import { PanelModule						} from 'primeng/primeng';
import { PanelMenuModule					} from 'primeng/primeng';
import { PasswordModule						} from 'primeng/primeng';
import { PickListModule						} from 'primeng/primeng';
import { ProgressBarModule					} from 'primeng/primeng';
import { RadioButtonModule					} from 'primeng/primeng';
import { RatingModule						} from 'primeng/primeng';
import { ScheduleModule						} from 'primeng/primeng';
import { ScrollPanelModule					} from 'primeng/primeng';
import { SelectButtonModule					} from 'primeng/primeng';
import { SlideMenuModule					} from 'primeng/primeng';
import { SliderModule						} from 'primeng/primeng';
import { SpinnerModule						} from 'primeng/primeng';
import { SplitButtonModule					} from 'primeng/primeng';
import { StepsModule						} from 'primeng/primeng';
import { TableModule						} from 'primeng/table';
import { TabMenuModule						} from 'primeng/primeng';
import { TabViewModule						} from 'primeng/primeng';
import { TerminalModule						} from 'primeng/primeng';
import { TieredMenuModule					} from 'primeng/primeng';
import { ToggleButtonModule					} from 'primeng/primeng';
import { ToolbarModule						} from 'primeng/primeng';
import { TooltipModule						} from 'primeng/primeng';
import { TreeModule							} from 'primeng/primeng';
import { TreeTableModule					} from 'primeng/primeng';
    
import { AppComponent						} from './app.component';
import { AppMenuComponent, 
		 AppSubMenuComponent				} from './app.menu.component';
import { AppTopBarComponent					} from './app.topbar.component';
import { AppFooterComponent					} from './app.footer.component';
// import { DashboardDemoComponent				} from './demo/view/dashboarddemo.component';
// import { SampleDemoComponent				} from './demo/view/sampledemo.component';
// import { FormsDemoComponent					} from './demo/view/formsdemo.component';
// import { DataDemoComponent					} from './demo/view/datademo.component';
// import { PanelsDemoComponent				} from './demo/view/panelsdemo.component';
// import { OverlaysDemoComponent				} from './demo/view/overlaysdemo.component';
// import { MenusDemoComponent					} from './demo/view/menusdemo.component';
// import { MessagesDemoComponent				} from './demo/view/messagesdemo.component';
// import { MiscDemoComponent					} from './demo/view/miscdemo.component';
// import { EmptyDemoComponent					} from './demo/view/emptydemo.component';
// import { ChartsDemoComponent				} from './demo/view/chartsdemo.component';
// import { FileDemoComponent					} from './demo/view/filedemo.component';
// import { UtilsDemoComponent					} from './demo/view/utilsdemo.component';
import { DocumentationComponent				} from './demo/view/documentation.component';
import { CountryService						} from './demo/service/countryservice';
import { EventService						} from './demo/service/eventservice';
import { NodeService						} from './demo/service/nodeservice';

import { HotelsComponent		 			} from './demo/view/hotels/component';
import { CheckinsComponent					} from './demo/view/checkins/checkins.component';
import { MapComponent 						} from './demo/view/map/map.component';
import { GroupsComponent 					} from './demo/view/groups/groups.component';
import { TransportsComponent 				} from './demo/view/transports/transports.component';
import { TransportsCalendarsComponent	    } from './demo/view/transportsCalendars/transportsCalendars.component';

import { FlightService 						} from './demo/service/flight.service';
import { CommonsService 					} from './demo/service/commons.service';
import { EntityService 						} from './demo/service/entity.service';
import { FlightsComponent 					} from './demo/view/entities/flights/flights.component';
import { VehiclesComponent 					} from './demo/view/entities/transports/vehicles.component';
import { TransportCompaniesComponent 		} from './demo/view/entities/transports/companies.component';
import { DriversComponent 					} from './demo/view/entities/transports/drivers.component';

import { AgmCoreModule 						} from '@agm/core';
import { AgmSnazzyInfoWindowModule      	} from '@agm/snazzy-info-window';
import { AgmJsMarkerClustererModule     	} from '@agm/js-marker-clusterer';
import { AgmDirectionModule             	} from 'agm-direction'   // agm-direction

import { DragulaModule 						} from 'ng2-dragula';
import { AngularFireModule              	} from '@angular/fire';
import { environment                    	} from '../environments/environment';
import { AngularFireAuthModule          	} from '@angular/fire/auth';
import { AngularFirestoreModule         	} from '@angular/fire/firestore';

import { MapService                     	} from './demo/service/mapservice';
import { CardModule                    		} from 'primeng/card';
import { MarkerTransferComponent 			} from './demo/view/map/components/markerTransfer/markerTransfer.component';
import { MarkerLodgingRouteComponent 		} from './demo/view/map/components/markerLodgingRoute/markerLodgingRoute.component';
import { LegendComponent 					} from 'src/app/demo/view/map/components/legend/legend.component';
import { TransferPanelComponent 			} from 'src/app/demo/view/map/components/transferPanel/transferPanel.component';
import { TourPanelComponent             	} from 'src/app/demo/view/map/components/tourPanel/tourPanel.component';
import { MapConfigComponent             	} from './demo/view/mapconfig/mapconfig.component';
import { StorageService                 	} from './demo/service/storageservice';
import { CommonModule                   	} from '@angular/common';
import { ToastrModule                   	} from 'ngx-toastr';
import { GroupButtonComponent           	} from './demo/view/map/components/groupButton/groupButton.component';
import { MessageDriverComponent         	} from './demo/view/map/components/messageDriver/messageDriver.component';
import { ListMessagesComponent          	} from './demo/view/map/components/listMessages/listMessages.component';
import { LandingComponent               	} from './demo/view/landing/landing.component';

import { HttpClientModule, HttpClient    	} from '@angular/common/http';
import { TranslateModule, TranslateLoader	} from '@ngx-translate/core';
import { TranslateHttpLoader             	} from '@ngx-translate/http-loader';

import { AuthFirebaseService            	} from './demo/service/database/authfirebase.service';
import { FirebaseService 					} from './demo/service/database/firebase.service';
import { CompanyService                     } from './demo/service/database/company.service';
// import { ComponentsModule } from './components/components.module';
import { ScrolledItemsComponent         	} from './components/scrolled-items/scrolled-items';
import { ListItemsComponent             	} from './components/list-items/list-items';

import { MarkerLodgingComponent 			} from './demo/view/map/components/markerLodging/markerLodging.component';
import { MapPricingComponent               	} from './demo/view/map_pricing/mappricing.component';
import { AngularDraggableModule 			} from 'angular2-draggable';
import { GeneratorPricingComponent          } from './demo/view/pricing/generatorPricing/generatorpricing.component';
import { MapCheckDistancesComponent         } from './demo/view/mapconfig/mapcheckdistances/mapcheckdistances.component';
import { StaffComponent                     } from './demo/view/entities/staff/staff.component';
import { AngularFireStorageModule           } from '@angular/fire/storage';
import { ProfileComponent                   } from './demo/view/profile/profile.component';
import { ProfileInfoComponent               } from './components/profile-info/profile-info.component';
import { ProvidersComponent                 } from './demo/view/entities/providers/providers.component';
import { AdaptableFormComponent             } from './components/adaptable-form/adaptable-form.component';
import { LodgingCheckerComponent            } from './components/lodging-checker/lodging-checker.component';
import { HttpModule                         } from '@angular/http';
import { CalendarModule as AngularCalendar, 
		 DateAdapter 						} from 'angular-calendar'; 
import { adapterFactory 					} from 'angular-calendar/date-adapters/date-fns';
import { ChartBookingsComponent 			} from './demo/view/statistics/bookings/bookings.component';
import { CalendarToursPriceTableComponent   } from './components/calendar-tours-price-table/calendar-tours-price-table.component';
import localeEs                               from '@angular/common/locales/es';

import { ToursItemsListComponent           	} from './components/tours-items-list/tours-items-list.component';
import { ToursOffersComponent 				} from './components/tours-offers/tours-offers.component';
import { OffersTemplatePeriodComponent 		} from './components/tours-offers/offers-template-period/offers-template-period.component';
import { CalendarToursOffersComponent 		} from './components/tours-offers/calendar-tours-offers/calendar-tours-offers.component';

// TEST COMPONENTS
import { TestComponent                    	} from './demo/view/test/test.component';

// TTOO COMPONENTS
import { HoppaService                       } from './demo/service/integrations/hoppa.service';

// TOUR COMPONENTS
import { TourContainerComponent            	} from './demo/view/tours/main/component';
import { ToursPriceTableComponent 			} from './components/tours-price-table/tours-price-table.component';
import { ToursPeriodTableComponent 			} from './components/tours-period-table/tours-period-table.component';
import { ToursPovComponent                	} from './components/tours-pov/tours-pov.component';

import { TourWizardServicesComponent       	} from './components/tours/wizard/steps/services/component';
import { TourWizardComponentsComponent    	} from './components/tours/wizard/steps/complements/component';
import { TourWizardSightsComponent         	} from './components/tours/wizard/steps/sights/component';
import { TourWizardResourcesComponent     	} from './components/tours/wizard/steps/resources/component';
import { TourWizardInfoComponent          	} from './components/tours/wizard/steps/info/component';
import { TourWizardI18nComponent 			} from './components/tours/wizard/steps/i18n/component';
import { TourWizardPickupsComponent 		} from './components/tours/wizard/steps/pickups/component';

import { ToursScheduleComponent 			} from './components/tours-schedule/tours-schedule.component';
import { ToursPricingComponent 				} from './components/tours-pricing/tours-pricing.component';
import { PictureEditorComponent             } from './components/editors/picture/component';

import { TourComponent                    	} from './components/tours/tours/list/component';
import { TourWizardComponent               	} from './components/tours/tours/wizard/component';
import { TourDetailComponent 				} from './components/tours/tours/detail/component';

import { SightComponent                     } from './components/tours/sights/list/component';
import { SightWizardComponent             	} from './components/tours/sights/wizard/component';
import { SightDetailComponent 				} from './components/tours/sights/detail/component';
import { WizardSightStepComponent 			} from './components/tours/wizard/steps/sight/component';

import { TourVariantComponent 				} from './components/tours/variants/list/component';
import { TourVariantDetailComponent 		} from './components/tours/variants/detail/component';

import { TourPickupComponent                } from './components/tours/pickups/list/component';

import { LyThemeModule, LY_THEME            } from '@alyle/ui';
import { LyButtonModule                     } from '@alyle/ui/button';
import { LyToolbarModule                    } from '@alyle/ui/toolbar';
import { LyResizingCroppingImageModule    	} from '@alyle/ui/resizing-cropping-images';
import { MinimaLight, MinimaDark 			} from '@alyle/ui/themes/minima';
import { InfoMessageComponent             	} from './components/info-message/info-message.component';
import { PickupsZonesComponent             	} from './demo/view/mapconfig/pickupsZones/pickupszones.component';
import { NotificationsComponent 			} from './components/notifications/notifications.component';
import { ChatComponent 						} from './components/chat/chat.component';
import { BookingVoucherComponent 			} from './components/booking-voucher/booking-voucher.component';
import { FormIssueComponent 				} from './components/issues/form-issue/form-issue.component';

import { SuperTableComponent 				} from './components/super-table/super-table.component';
import { SuperFormComponent 				} from './components/super-form/component';

import { AdminStaffComponent             	} from './components/admin/staff/component';
import { ResellerStaffComponent            	} from './components/reseller/staff/component';

import { CompanyContainerComponent			} from './demo/view/company/main/component';
import { CompanyInfoComponent            	} from './components/company/info/component';
import { CompanyDestinationsComponent     	} from './components/company/destinations/component';
import { CompanyStaffComponent             	} from './components/company/staff/component';
import { CompanyProvidersComponent 			} from './demo/view/company/providers/component';
import { CompanyTransporterComponent     	} from './demo/view/company/transporters/component';
import { CompanyDriverComponent 			} from './demo/view/company/drivers/component';
import { CompanyFleetComponent 				} from './demo/view/company/fleet/component';
import { CompanyAccountingComponent 		} from './components/company/accounting/component';
import { CompanyResourcesComponent 			} from './components/company/resources/component';
import { CompanyTimesheetComponent 			} from './components/company/timesheet/component';
import { CompanyStatsComponent 				} from './components/company/stats/component';
import { CompanyExpensesComponent 			} from './components/company/expenses/component';
import { CompanyServicesComponent 			} from './components/company/services/component';
import { CompanyInvoicesComponent        	} from './components/company/invoices/component';
import { CompanyExpendituresComponent 		} from './components/company/expenditures/component';
import { CompanyGarageComponent 			} from './components/company/garage/component';
import { CompanyIncomesComponent 			} from './components/company/incomes/component';
import { CompanyConciliationComponent     	} from './components/company/conciliation/component';

import { ProviderDetailComponent     		} from './components/provider/detail/component';
import { ProviderServicesComponent         	} from './components/provider/services/component';
import { ProviderListComponent            	} from './components/provider/list/component';
import { ProviderConciliationComponent 		} from './components/provider/conciliation/component';

import { DriverListComponent             	} from './components/company/drivers/services/component';
import { InvoicingCompanyComponent         	} from './demo/view/invoicing/company/component';

import { TransporterServicesComponent 		} from './components/transporter/services/component';
import { TransporterFleetComponent        	} from './components/transporter/fleet/component';
import { TransporterListComponent 			} from './components/transporter/list/component';

import { DashboardComponent 				} from './demo/view/dashboard/component';
import { DashboardAggregatorComponent 		} from './demo/view/dashboard_aggregator/component';
import { CreateDestinationComponent         } from './demo/view/destinations/create/create-destination.component';
import { AppStepperComponent                } from './components/stepper/app-stepper.component';
import { CreateCompanyComponent             } from './demo/view/company/create/create-company.component';
import { MapGeneratorDestinationsComponent  } from './demo/view/mapconfig/generatorDestinations/mapGeneratorDestinations.component';
import { DestinationsService                } from './demo/service/database/destinations.service';
import { AppSpinnerComponent                } from './components/spinner/app-spinner.component';
import { BasicDataComponent                 } from './demo/view/noDestinationView/basicdata.component';
import { AppProfileDestinationComponent     } from './components/app-profile-destination/app-profile-destination.component';
import { GoogleService 						} from './demo/service/google/google.service';
import { LodgingService 					} from './demo/service/lodging/lodging.service';
import { CompanyModulesComponent 			} from './components/company/modules/app-company-modules.component';
import { AdminContainerComponent            } from './demo/view/admin/main/component';
import { ResellerContainerComponent         } from './demo/view/reseller/main/component';
import { StationsComponent                  } from './components/company/stations/app-stations.component';
import { MainProvidersComponent             } from './components/provider/main/main-providers.component';
import { BookingsService                    } from './demo/service/bookings/bookings.service';

import { FlightCheckerComponent             } from './components/flight-checker/flight-checker.component';
import { TransporterDetailComponent         } from './components/transporter/detail/component';
import { TransporterConciliationComponent   } from './components/transporter/conciliation/component';
import { DashboardResellerComponent         } from './demo/view/reseller/dashboard/component';
import { SuperformService                   } from './demo/service/super-form/superform.service';
import { AppListCountriesComponent          } from './components/app-list-countries/app-list-countries.component';
import { AppTreeDestinationsComponent       } from './components/app-tree-destinations/app-tree-destinations.component';
import { AppAdminTranslateComponent         } from './components/app-admin-translate/app-admin-translate.component';
import { ManageTransportsComponent          } from './demo/view/company/manage-transports/manage-transports.component';
import { AppTransportFleetComponent         } from './components/app-transport-fleet/app-transport-fleet.component';
import { ListPricesComponent                } from './components/provider/list-prices/list-prices.component';
import { AgmOverlays                        } from "agm-overlays"
import { AggregatorsService                 } from './demo/service/database/aggregator.service';
import { AppMapZonesComponent            	} from './components/app-map-zones/app-map-zones.component';
import { ManageListPricesComponent 			} from './demo/view/company/manage-list-prices/manage-list-prices.component';
import { ParticlesDirective 				} from './particles.directive';
import { AppSummaryOnboardingComponent      } from './components/app-summary-onboarding/app-summary-onboarding.component';
import { ImporterComponent                  } from './demo/view/importer/importer.component';

import { MatAutocompleteModule, 
         MatBadgeModule, 
         MatBottomSheetModule, 
         MatButtonModule, 
         MatButtonToggleModule, 
         MatCardModule, 
         MatCheckboxModule, 
         MatChipsModule, 
         MatDatepickerModule, 
         MatDialogModule, 
         MatDividerModule, 
         MatExpansionModule, 
         MatFormFieldModule, 
         MatGridListModule, 
         MatIconModule, 
         MatInputModule, 
    	 MatListModule, 
         MatMenuModule, 
         MatNativeDateModule, 
         MatPaginatorModule, 
         MatProgressBarModule, 
         MatProgressSpinnerModule, 
         MatRadioModule, 
         MatRippleModule, 
         MatSelectModule, 
         MatSidenavModule, 
         MatSliderModule, 
         MatSlideToggleModule, 
         MatSnackBarModule, 
         MatSortModule, 
         MatStepperModule, 
         MatTableModule, 
         MatTabsModule, 
         MatToolbarModule, 
         MatTooltipModule, 
         MatTreeModule 						} from '@angular/material';
import { OverlayModule					 	} from '@angular/cdk/overlay';
import { PortalModule 						} from '@angular/cdk/portal';
import { ScrollingModule 					} from '@angular/cdk/scrolling';
import { ManageZonesComponent               } from './demo/view/company/zones/manage-zones.component';

import { AppExternalTransporters            } from './components/external-transporters/container/app-external-transporters.component';
import { AppExternalTransportersPrices      } from './components/external-transporters/prices/prices.component';

import { FomentoService                     } from './demo/service/fomento/fomento.service';
import { CheckService                       } from './demo/service/database/check.service';
import { SimpleFirebaseService              } from './demo/service/database/simplefirebase.service';
import { ServiceFiltersService              } from './demo/service/serviceFilters/service-filters.service';
import { ImporterService                    } from './demo/service/importer/importer.service';
import { TransportService                   } from './demo/service/transports/transports.service';
import { TransportDriverService             } from './demo/service/transports/driver.service';
import { TransportFleetService 				} from './demo/service/transports/fleet.service';
import { TransportTransporterService 		} from './demo/service/transports/transporter.service';
import { TransportTypeService 				} from './demo/service/transports/type';
import { MyDialogComponent                  } from './my-dialog/my-dialog.component';

import { ProviderServiceController          } from './services/providers/controller.service';
import { BookingsComponent                  } from './demo/view/bookings/bookings.component';
import { ExternalsComponent                 } from './demo/view/externals/externals.component';
import { BookingExpanderComponent           } from './components/booking-expander/booking-expander.component';
import { TabletemplateComponent             } from './demo/view/tabletemplate/tabletemplate.component';
import { CSVService                         } from './demo/service/csv.service';
import { Checkins2Component                 } from './demo/view/checkins2/checkins2.component';

import { ProviderWTRService                 } from './services/providers/wtr.service';
import { ProviderHTXService                 } from './services/providers/htx.service';
import { ProviderHoppaService               } from './services/providers/hoppa.service';
import { TrackingComponent                  } from './demo/view/tracking/tracking.component';
import { PickupsComponent                   } from './demo/view/pickups/component';

// AoT requires an exported function for factories
// export function HttpLoaderFactory(http: HttpClient) {
//     return new TranslateHttpLoader(http);
// }
registerLocaleData(localeEs);

export function createTranslateLoader(http: HttpClient) {    
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');    
}

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutes,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ColorPickerModule,
        SharedModule,
        ContextMenuModule,
        DataGridModule,
        DataListModule,
        DataScrollerModule,
        DataTableModule,
        DataViewModule,
        DialogModule,
        DragDropModule,
        DropdownModule,
        EditorModule,
        FieldsetModule,
        FileUploadModule,
        GalleriaModule,
        GMapModule,
        GrowlModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessagesModule,
        MessageModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        ScheduleModule,
        ScrollPanelModule,
        SelectButtonModule,
        SlideMenuModule,
        SliderModule,
        SpinnerModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        ProgressSpinnerModule,
        SidebarModule,
        ConfirmDialogModule,
        CardModule,

		// Alyle UI
		BrowserAnimationsModule,
	    LyThemeModule.setTheme('minima-light'),
		LyButtonModule,
		// LyIconModule,
		// LyExpansionIcon,
	    LyToolbarModule,
		LyResizingCroppingImageModule,
		// End Alyle UI

        // ComponentsModule,

        AgmCoreModule.forRoot(  {   apiKey      : 'AIzaSyDXGo6MTulw5-o7s-4G3Wioa5KgnY4cRyM',        // Tourinia
                                    libraries   : ["places","geometry"]}),
        AgmSnazzyInfoWindowModule,
        AgmJsMarkerClustererModule,
        AgmDirectionModule,
        DragulaModule.forRoot(),
        CommonModule,
        ToastrModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebase),
        // AngularFireModule.initializeApp(environment.firebaseSecondary),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireStorageModule,
        AngularDraggableModule,
        BrowserAnimationsModule,
        AgmOverlays,
        AngularCalendar.forRoot({ 	provide: DateAdapter, useFactory: adapterFactory }),
		TranslateModule.forRoot({	loader: {	provide: TranslateLoader,useFactory: (createTranslateLoader), deps: [HttpClient]	}
		// TranslateModule.forRoot({	loader: {	provide: TranslateLoader,useFactory: (createTranslateLoader), deps: [HttpClient]	}
		}),

        MatButtonModule,
        MatTabsModule,
        MatSelectModule,
        MatIconModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        OverlayModule,
        PortalModule,
        ScrollingModule,
        MatFormFieldModule,
        MatInputModule,
        MatRippleModule
    ],
    declarations: [
        ParticlesDirective,
        AppComponent,
        AppMenuComponent,
        AppSubMenuComponent,
        AppTopBarComponent,
        AppFooterComponent,
        // DashboardDemoComponent,
        // SampleDemoComponent,
        // FormsDemoComponent,
        // DataDemoComponent,
        // PanelsDemoComponent,
        // OverlaysDemoComponent,
        // MenusDemoComponent,
        // MessagesDemoComponent,
        // MessagesDemoComponent,
        // MiscDemoComponent,
        // ChartsDemoComponent,
        // EmptyDemoComponent,
        // FileDemoComponent,
        // UtilsDemoComponent,
        DocumentationComponent,
        CheckinsComponent,
        TrackingComponent,
        Checkins2Component,
        MapComponent,
        BookingsComponent,
        GroupsComponent,
        TransportsComponent,
        TransportsCalendarsComponent,
        ExternalsComponent,
        HotelsComponent,
        FlightsComponent,
        VehiclesComponent,
        StaffComponent,
        TransportCompaniesComponent,
        DriversComponent,
        MarkerTransferComponent,
        MarkerLodgingRouteComponent,
        MarkerLodgingComponent,
        LegendComponent,
        TransferPanelComponent,
        TourPanelComponent,
        MapConfigComponent,
        MapPricingComponent,
        GeneratorPricingComponent,
        MapCheckDistancesComponent,
        GroupButtonComponent,
        MessageDriverComponent,
        ListMessagesComponent,
        LandingComponent,
        ScrolledItemsComponent,
        ListItemsComponent,
        ProfileComponent,
        ProfileInfoComponent,
        ProvidersComponent,
        AdaptableFormComponent,
        LodgingCheckerComponent,
        
		// TEST COMPONENTS
		TestComponent,
        PickupsComponent,
        TourPickupComponent,
        
		// TOURS COMPONENTS
        TourComponent,
        TourWizardComponent,
        TourWizardServicesComponent,
		TourWizardComponentsComponent,		
        TourWizardSightsComponent,
        TourWizardResourcesComponent,
		TourWizardInfoComponent,
        TourWizardI18nComponent,
        TourWizardPickupsComponent,

        TourDetailComponent,

		// SIGHTS COMPONENTS
        SightComponent,
		SightWizardComponent,
		SightDetailComponent,
		WizardSightStepComponent,
		
		// VARIANTS COMPONENTS
		TourVariantComponent,
		TourVariantDetailComponent,

        // EDITORS
        PictureEditorComponent,
        
		TourContainerComponent,
        ToursPriceTableComponent,
        ToursPeriodTableComponent,
        CalendarToursPriceTableComponent,
        ChartBookingsComponent,
        ToursItemsListComponent,
        ToursOffersComponent,
        OffersTemplatePeriodComponent,
        CalendarToursOffersComponent,
        ToursPovComponent,
        ToursScheduleComponent,
        ToursPricingComponent,
        InfoMessageComponent,
        PickupsZonesComponent,
        BookingVoucherComponent,
        
        InvoicingCompanyComponent,

        NotificationsComponent,
        ChatComponent,
        FormIssueComponent,
		
		SuperTableComponent,
		SuperFormComponent,

        ProviderListComponent,
        ProviderDetailComponent,
        ProviderConciliationComponent,
        ProviderServicesComponent,        

		AdminContainerComponent,
		AdminStaffComponent,
		
		ResellerContainerComponent,
        ResellerStaffComponent,

        CompanyContainerComponent,
        CompanyInfoComponent,
        CompanyAccountingComponent,
        CompanyResourcesComponent,
        CompanyStatsComponent,
        CompanyStaffComponent,
        CompanyDestinationsComponent,
        CompanyTimesheetComponent,
        CompanyExpensesComponent,
        
        CompanyProvidersComponent,
		CompanyTransporterComponent,
		CompanyDriverComponent,
        CompanyFleetComponent,
        CompanyServicesComponent,
        CompanyInvoicesComponent,
        CompanyExpendituresComponent,
        CompanyGarageComponent,
        CompanyIncomesComponent,
        CompanyConciliationComponent,

        DriverListComponent,

        TransporterListComponent,
        TransporterServicesComponent,
        TransporterFleetComponent,
        TransporterDetailComponent,
        TransporterConciliationComponent,  
        
        FlightCheckerComponent,
        
        DashboardComponent,
        DashboardAggregatorComponent,
        DashboardResellerComponent,
        
        CreateDestinationComponent,
        CreateCompanyComponent,
        AppStepperComponent,
        MapGeneratorDestinationsComponent,
        AppSpinnerComponent,
        BasicDataComponent,
        AppProfileDestinationComponent,
        CompanyModulesComponent,
        StationsComponent,
        MainProvidersComponent,
        AppListCountriesComponent,
        AppTreeDestinationsComponent,
        AppAdminTranslateComponent,
        ManageTransportsComponent,
        AppTransportFleetComponent,
        AppExternalTransporters,
        AppExternalTransportersPrices,
        ListPricesComponent,
        ManageZonesComponent,
        AppMapZonesComponent,
        ManageListPricesComponent,
        AppSummaryOnboardingComponent,
        ImporterComponent,
        MyDialogComponent,
        BookingExpanderComponent,
        TabletemplateComponent,
        TrackingComponent
    ],
    providers: [
        ProviderServiceController,
        ProviderHoppaService,
        ProviderHTXService,
        ProviderWTRService,
        FomentoService,
        ServiceFiltersService,
        ImporterService,
        TransportService,
        TransportDriverService,
        TransportFleetService,
        TransportTransporterService,
        TransportTypeService,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
		CountryService, 
		EventService, 
		NodeService,
		EntityService, 
		CommonsService, 
        CSVService,
		FlightService,
        MapService,
        StorageService,
        AuthFirebaseService,
        AuthGuard,
        FirebaseService,
        SimpleFirebaseService,
        CompanyService,
        DestinationsService,
        ConfirmationService,
        IntegrationFactoryService,
        HoppaService,
        GoogleService,
        LodgingService,
        BookingsService,
        SuperformService,
        TransportService,
        AggregatorsService,
        CheckService,

		// Alyle
		{ provide: LY_THEME, useClass: MinimaLight, multi: true }, 	// name: `minima-light`
	    { provide: LY_THEME, useClass: MinimaDark, 	multi: true } 	// name: `minima-dark`
    ],
    bootstrap: [ AppComponent ]
})
export class AppModule { }
