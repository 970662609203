import { MapService } from 'src/app/demo/service/mapservice';
import { Component,
         Input,
         Output,
         EventEmitter,
		 ViewEncapsulation	} from '@angular/core';
import { CommonsService 	} from 'src/app/demo/service/commons.service';
import { EntityService 		} from 'src/app/demo/service/entity.service';
import { buttons 			} from './data/buttons';
import { filters 			} from './data/filters';
import { entities 			} from './data/items';
import { FirebaseService 	} from './../../../../demo/service/database/firebase.service';

@Component({
  selector    		: 'app-tour-pickup',
  styleUrls			: ['./component.scss'],
  encapsulation		: ViewEncapsulation.None,
  templateUrl 		: 'component.html'
})
export class TourPickupComponent {

  	@Input("type")      type:string;
	@Output()			emitter			= new EventEmitter<any>();

	pageInfo  	: any 			= {};
	uploadFiles	: any[] 		= [];
	fileBuffer	: string 		= "";
	fileLines	: string[] 		= [];
	debug		: string 		= "";
	file		: File;

	constructor(
		private commons			: CommonsService,
		private entityService	: EntityService,
		private firebaseCtrl    : FirebaseService,
		private mapService		: MapService
	){
		this.init();
	}

	init()				{
		this.pageInfo.dataLoaded	= false;
		this.pageInfo.results		= {};
		this.pageInfo.today 		= new Date();
		this.pageInfo.uploadedFiles	= [];
		this.pageInfo.buttons		= buttons;
		this.pageInfo.filters		= this.commons.translateRecursively(filters,{ label: "label", children: "items" });
		this.pageInfo.entities		= { "pickups" : [], "pickupZones": [], "types": [], "zones": [], "areas": [] };
		this.pageInfo.areas			= [];
		this.pageInfo.zoneSelected	= "";
		this.pageInfo.searchArea	= "";
		this.pageInfo.searchPickup	= "",
		this.pageInfo.tabs			= {
			items 			: [
				{ id:0, label: this.commons.getTranslate('_LIST'		),	icon : 'fa fa-folder-o'		, command: ($event)=> { this.setTabItem($event)} 	},
				{ id:1, label: this.commons.getTranslate('_VIEW_MAP'	), 	icon : 'fa fa-map-marker'	, command: ($event)=> { this.setTabItem($event)} 	},
				{ id:2, label: this.commons.getTranslate('_EDITOR_MAP'	), 	icon : 'fa fa-map-marker'	, command: ($event)=> { this.setTabItem($event)} 	}			
			],
			selected		: 0
		};		
	}

	loadEntities()		{ 	this.load("zones"); 
							this.load("types"); 
							this.load("pickups"); 
							this.load("pickupZones"); 
							this.pageInfo.dataLoaded = true;
						}
	async ngOnInit()	{ 
		this.pageInfo.map		= { zoom: 10, lat: 39.639938, lng: 2.894679 };		
		this.pageInfo.pickups	= this.pageInfo.pickups || [];
		await this.loadEntities();
	}

	setTabItem($event)	{	this.pageInfo.tabs.selected = $event.item.id;	}

	/**
	 * Upload content
	 * @param event 
	 */
    onUpload(event) 	{
        for(let file of event.files) { this.pageInfo.uploadedFiles.push(file); }
        this.commons.generateToast('File Uploaded','','info');
	}

	action($type,$item?,$extra?){
		switch($type){
			case "button"			:	switch($item.name){
											case "new_pickup"	: this.pageInfo.entities["pickups"] = [{ dirty: true, name_orig: "_____" },...this.pageInfo.entities["pickups"]]; break;
											case "reload"		: this.load("pickups");	break;
											case "save"			: this.savePickups(); 	break;
											case "set_areas"	: this.setAreas(); break;
											case "export"		: this.commons.generateToast("Button pressed","Export","info");	break;
											case "import"		: this.commons.generateToast("Button pressed","Import","info"); break;
										}
										break;
			case "view_pickup"		:	this.emitter.emit({ action: 'view_pickup', item: $item 	});	break;
			case "toggleArea"		: 	let isAreaSelected 				= this.pageInfo.areas.some(item=>item.name==$item.name);
										if(isAreaSelected) {
											$item.selected 				= false;
											this.pageInfo.areas			= this.pageInfo.areas.filter(item=>item.name!=$item.name);
										} else {
											$item.selected 				= true;
											this.pageInfo.areas			= [ ...this.pageInfo.areas, $item ];
										}
										break;
			case "toggleAreaViewAll":	this.pageInfo.areaViewAll 		= undefined==this.pageInfo.areaViewAll?true:!this.pageInfo.areaViewAll;
										this.pageInfo.entities.areas.forEach(item=>{ item.selected = this.pageInfo.areaViewAll; });
										this.pageInfo.areas				= this.pageInfo.entities.areas.filter(item=>item.selected);
										break;
			case "togglePickup"		: 	let isPickupSelected 			= this.pageInfo.pickups.some(item=>item.name==$item.name);
										this.pageInfo.entities.pickups.forEach(item=>{ item.selected = false; });
										$item.selected 					= !isPickupSelected;
										this.pageInfo.pickups			= isPickupSelected?[]:[$item];
										break;
			case "deletePickup"		:	this.deletePickup($item); break;

			case "uploadBeforeSend"	: 	console.log("uploadBeforeSend",	$item); break;
			case "uploadBefore"		:	console.log("uploadBefore",		$item); break;
			case "uploadDone"		:	this.uploadDone($item); 				break;
			case "uploadFilesEnd"	:	this.uploadPickupFilesEnd($item); 		break;
			case "uploadSelect"		:	console.log("uploadSelect",		$item); break;
			case "uploadRemove"		:	console.log("uploadRemove",		$item); break;
			case "toggleItemField"	:	switch($extra){
											case "type":	$item.editing = $item.editing==true?false:true; break;
										}
										break;
			case "setItemField"		:	switch($extra){
											case "type":	$item.type 		= $item.optionSelected;
															$item.editing	= false;
															break;
											case "zone":	$item.zone		= $item.optionSelected;
															$item.editing	= false;
															break;
										}
										break;
			case "setMarker"		: 	this.pageInfo.pickups.forEach(item=>{
											item.latitude	= $item.coords.lat,
											item.longitude	= $item.coords.lng
										});
										break;
		}
	}

	/**
	 * Found and assign areas from our zone areas polygons
	 */
	async setAreas(){
		this.pageInfo.entities["pickups"] = this.pageInfo.entities["pickups"].map(item=>{
			// Avoid items with area found or those with no coordinates
			if(undefined==item.latitude || undefined==item.longitude || null!=item.area){ return item; }
			let result = this.pageInfo.entities["pickupZones"].reduce((o,zone)=>{
				if(o.found){ return o; }
				let areaFound 	= zone["areas"].find(area=>this.mapService.isPointInsidePolygon(area.coords, item.latitude, item.longitude));
				if(null!=areaFound){
					o.found	= true;
					o.area	= areaFound.name;
					o.zone	= zone.name;
				}
				return o; 
			},{ found: false, area: null, zone: "" });
			if(result.found){
				item.zone	= result.zone;
				item.dirty	= item.area!=result.area;
				item.area	= result.area;					
			}
			console.log("Item",item);
			return item;
		});
		console.log("PICKUPS",this.pageInfo.entities["pickups"]);
		this.savePickups();
	}

	async savePickups()				{
		let records = this.pageInfo.entities["pickups"].filter(item=>item.dirty);
		if(records.length==0){ 
			this.commons.generateToast("_NOTICE", "_NO_CHANGES","info");
			return false; 
		}

		this.pageInfo.spinner 			= true;	
		await this.entityService.postJSON(
			this.entityService.getUrl('pickup_persist'), { records: records.map(item=>({ code: item.code, area: item.area })) }
		).then(response=>{
			this.pageInfo.spinner = false;
			if(response["success"]!=true){
				this.commons.generateToast("_ERROR", "_ERROR_SAVING","error");
				console.log("RESPONSE",response);
				return false;
			}
			this.commons.generateToast("_SUCCESS", "_SUCCESS_SAVING","success");
			this.load("pickups");
			return true;
		}).catch(response=>{
			this.pageInfo.spinner = false;
			this.commons.generateToast("_ERROR", "_ERROR_SAVING","error");
			console.log("RESPONSE",response);				
		});
		this.pageInfo.spinner 			= false;	
	}

	uploadDone($event)			{	this.load("pickups");										}
	uploadPickupFiles($event)	{ 	$event.files.forEach(file=>this.uploadFiles.push(file));	}
	uploadPickupFilesEnd($event){ 	this.readFile(this.uploadFiles[0]);							}
	readFile($file)				{ 	var reader = new FileReader();
									reader.onload 	= () => { this.fileBuffer+=reader.result; };
									reader.readAsText(this.file);
									this.fileLines 	= this.fileBuffer.split("\n");
								}
	async deletePickup($item)	{ 	console.log("Deleting pickup");
									await this.entityService.delete(this.entityService.getUrl('pickups'),{ id: $item.id })
									.then(response=>{
										this.pageInfo.spinner = false;
										if(response["success"]!=true){
											this.commons.generateToast("_ERROR", "_ERROR_DELETING","error");
											console.log("RESPONSE",response);
											return false;
										}
										this.commons.generateToast("_SUCCESS", "_SUCCESS_DELETING","success");
										this.load("pickups");		
										return true;
									}).catch(response=>{
										this.pageInfo.spinner = false;
										this.commons.generateToast("_ERROR", "_ERROR_DELETING","error");
										console.log("RESPONSE",response);				
									});
								}

	getMarkers($type)	{
		switch($type){
			case "viewPickups"		:	//return this.pageInfo.entities["pickups"].filter(pickup=>this.pageInfo.areas.some(areaItem=>areaItem.name==pickup.area));
										return this.pageInfo.entities["pickups"].filter(pickup=>{
											// Filter by zone
											if( this.pageInfo.zoneSelected!=""){
												if(pickup.zone.toLowerCase()!=this.pageInfo.zoneSelected){ return false; }
											}
											// Filter by area
											// if ( this.pageInfo.areas.filter(item=>item.name!="").length==0 ){ return true; }
											pickup.view 	= this.pageInfo.areas.some(areaItem=>areaItem.name==pickup.area);
											return pickup.view;
										});
			case "editablePickups"	: 	return this.pageInfo.entities.pickups.filter(item=>item.selected);
			case "selected"			:	return this.pageInfo.pickups;
		}
	}

	getLocationParsed($item){	return 	parseFloat($item["latitude"]).toFixed(4) + " , " + parseFloat($item["longitude"]).toFixed(4);	}
							
	getFilteredEntity($type){
		switch($type){
			case "pickupTree"	: 	return this.pageInfo.entities["pickups"].filter(pickup=>{
										if( true || this.pageInfo.zoneSelected!=""){
											if(pickup.zone.toLowerCase()!=this.pageInfo.zoneSelected){ return false; }
										}
										// Search by name
										return (undefined==this.pageInfo.searchPickup || this.pageInfo.searchPickup=="")
												?true
												:pickup.name.toLowerCase().includes(this.pageInfo.searchPickup.toLowerCase());
									});

			case "pickupList"	: 	this.checkEntityChanges("pickups");
									return this.pageInfo.entities["pickups"].filter(pickup=>{
										// Filter by zone
										if( true || this.pageInfo.zoneSelected!=""){
											if(pickup.zone.toLowerCase()!=this.pageInfo.zoneSelected){ return false; }
										}
										// Return all if area none selected
										// if ( this.pageInfo.areas.filter(item=>item.name!="").length==0 ){ return true; }
										pickup.view 	= this.pageInfo.areas.some(areaItem=>areaItem.name==pickup.area);
										return pickup.view;
									});

			case "areas"		: 	return this.pageInfo.entities["areas"].filter(area=>{
										if(area.name==null || area.name==""){ return false; }
										if( true || this.pageInfo.zoneSelected!=""){
											if(area.zone.toLowerCase()!=this.pageInfo.zoneSelected){ return false; }
										}		
										// Return all if none selected
										if(undefined==this.pageInfo.searchArea || this.pageInfo.searchArea==""){ return true; }												
										return area.name.toLowerCase().includes(this.pageInfo.searchArea.toLowerCase());
									});
		}
	}

	checkEntityChanges($entity){
		switch($entity){
			case "pickups":	this.pageInfo.entities[$entity].forEach(item=>{
								item.dirty = ["name","type","latitude","longitude"].some(field=>item[field]!=item[field+"_orig"]);
							})
		}
	}

	search(item,$event) {
		if(undefined==item.entityList){ return false; }
		this.pageInfo.results[item.field] =  this.pageInfo.entities[item.entityList].filter(item => item.name.includes($event.query)).map(item=>item.name);
	}

	async load($entity){
		let response;
		this.pageInfo.spinner 	= true;	
		switch($entity){
			case 'pickups' :	response 						= await Promise.resolve(this.entityService.getRequest("pickups"));
								this.pageInfo.entities[$entity]	= response["data"].map(item=>{
									item["dirty"]			= false;					 
									item["name_orig"]		= item["name"];
									item["type_orig"]		= item["type"];
									item["zone"]			= item["zone"].toLowerCase();
									item["zone_orig"]		= item["zone"];
									item["latitude_orig"]	= item["latitude"];
									item["longitude_orig"]	= item["longitude"];						
									return item;
								});
								this.pageInfo.entities["areas"]	= this.pageInfo.entities[$entity]
																		.reduce((a,item)=>a.some(aItem=>item.area==aItem.name)
																							?a
																							:[...a,{ 	name	: item.area, 
																										zone	: item.zone.toLowerCase(), 	// Get zone to filter
																										selected: false						// selected by default 
																									}
																							]
																				,[]);
								this.action('toggleViewAll');			
								break;
			case 'types'	:	response 						= await Promise.resolve(this.entityService.getRequest("pickup_types"));
								this.pageInfo.entities[$entity]	= response["data"].map(item=>{
									item["label"] 			= this.commons.getTranslate(item["value"]);
									return item;
								});
								this.pageInfo.entities[$entity+"_original"] = this.pageInfo.entities[$entity];
								this.pageInfo[$entity]			= {
									field		: "type",
									entityList	: "types"
								};	
								break;
			case 'zones'	:	response 						= await Promise.resolve(this.entityService.getRequest("pickup_zones"));
								this.pageInfo.entities[$entity]	= response["data"].map(item=>{
									item["label"] 	= item["value"].charAt(0).toUpperCase()+item["value"].slice(1).toLowerCase();
									item["value"]	= item["value"].toLowerCase();
									return item;
								});
								break;
			
			case 'pickupZones': response						= await this.firebaseCtrl.getPickupsZones('1','1');
								this.pageInfo.entities[$entity] = response;								 
								console.log('PICKUP ZONES', this.pageInfo.entities[$entity]);
								break;
		}
		this.pageInfo.spinner 	= false;	
	}
}
	